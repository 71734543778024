<template>

    <div style="margin-bottom: 16px;">
        <b-card class="p-relative m-0" no-body style="border-radius: 16px !important;">
            
            <b-dropdown
                v-if="miner.status !== 4"
                size="sm"
                right
                no-caret   
                variant="transparent"
                style="position: absolute; right: 16px; top: 8px;"
                class="group-picker"
            >
                <template #button-content>
                     <feather-icon icon="MoreHorizontalIcon" class="text-muted" size="22" />
                </template>
                <b-dropdown-item @click="refund">
                                     {{$t('contracts.return-miner')}}

                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="tradein">
                   {{$t('contracts.trade-in')}}
                </b-dropdown-item>
            </b-dropdown>
            
            <b-card-body style="padding: 16px 20px 16px 20px">
                               
                <p style="margin-bottom: 6px;"> {{$t('statistics.net-profit')}} <b-link @click="changeCurrency"> {{ ticker }}</b-link> </p>

                <div class="d-flex">
                    <p style="font-size: 28px; font-weight: 600;">
                        {{ (miner.current_balance * $blockchain.tickers.values[ticker].last).formatMoney( ticker === "BTC" ? 8 : 2, ' ', ' ') }} {{ User.getSymbol() }} 
                    </p>
                </div>
            
                <div style="margin-bottom: 12px;">
                    <span class="block-view">
                        {{ (miner.total_balance * $blockchain.tickers.values[ticker].last).formatMoney(2, ' ', ' ') }} {{ User.getSymbol() }}  <span class="text-muted">{{$t('statistics.total-profit')}}</span>
                    </span>
                </div>
                <div>
                    <span class="block-view">
                        {{ miner.machine.hashrate.formatMoney(0,' ', ' ') }} TH/s  <span class="text-muted" style="text-transform: lowercase">{{$t('statistics.total-capacity')}}</span>
                    </span>
                </div>
      
            </b-card-body>

            <hr class="m-0">

            <b-card-body style="padding: 16px 20px 16px 20px">
                
                <div style="font-size: 14px; margin-bottom: 16px;">
                    <div class="d-flex justify-content-between" style="margin: 8px 0;">
                        <span class="text-muted font-weight-light">
                            {{$t('statistics.investments')}}
                        </span>
                        <span>
                           {{ convertUSDtoBTC( miner.machine.price_amount ).toFixed(5) }} BTC
                        </span>
                    </div>

                    <div class="d-flex justify-content-between" style="margin: 8px 0;">
                        <span class="text-muted font-weight-light">
                            {{$t('statistics.date-start')}}
                        </span>
                       <span v-if="miner.status === 4">
                            -
                        </span>
                        <span v-else>
                            {{ toTimeString(miner.start_at) }}
                        </span>
                    </div>
                    
                    <div class="d-flex justify-content-between" style="margin: 8px 0;">
                        <span class="text-muted font-weight-light">
                            {{$t('statistics.date-end')}}
                        </span>
                         <span v-if="miner.status === 4">
                            -
                        </span>
                        <span v-else>
                            {{ toTimeString(miner.end_at) }}
                        </span>
                    </div>
                    
                    <div class="d-flex justify-content-between" style="margin: 8px 0;">
                        <span class="text-muted font-weight-light">
                           {{$t('statistics.serial-number')}}
                        </span>
                        <span>
                           {{ miner.serial_number }} 
                        </span>
                    </div>

                    <div v-if="miner.guarantee == 4">
                        <div class="d-flex justify-content-between" style="margin: 8px 0;">
                            <span class="text-muted font-weight-light">
                            {{$t('statistics.guarantee')}}
                            </span>
                            <span>
                                3 {{$t('statistics.years')}}
                            </span>
                        </div>
                        <div class="d-flex justify-content-between" style="margin: 8px 0;">
                            <span class="text-muted font-weight-light">
                            {{$t('statistics.guarantee-hashrate')}}
                            </span>
                            <span>
                                3 {{$t('statistics.years')}}
                            </span>
                        </div>
                    </div>
                    <div v-else-if="miner.guarantee" class="d-flex justify-content-between" style="margin: 8px 0;">
                        <span class="text-muted font-weight-light">
                        {{$t('statistics.guarantee')}}
                        </span>
                        <span>
                            {{miner.guarantee}} 
                            <span v-if="miner.guarantee == 1">{{$t('statistics.year')}}</span>
                            <span v-else>{{$t('statistics.years')}}</span>
                        </span>
                    </div>
                    
                </div>

            </b-card-body>
            
            
            
        </b-card>
        
        
    </div>
    
</template>

<script>

    import User from "@/modules/user/"
    import Blockchain from "@/modules/blockchain"

    export default {

        data() {
            return {
                User,
                Blockchain
            }
        },
        props: {
            miner: {
                type: Object
            }
        },
        methods: {
            
            refund( args ) {
                this.$router.replace({
                    name: this.$route.name,
                    params: this.$route.params,
                    query: {
                        feedback: 1,
                        type: "miners",
                        action: "refund",
                        miner: this.miner.id
                    }
                });
            },
            
            tradein( args ) {
                this.$router.replace({
                    name: this.$route.name,
                    params: this.$route.params,
                    query: {
                        feedback: 1,
                        type: "miners",
                        action: "tradein",
                        miner: this.miner.id
                    }
                });
            },
            
            convertUSDtoBTC( value ) {
                return ( value / Blockchain.tickers.values['USD'].last );
            },
            
            getCurrentPercent() {
                return ((this.contract.total_balance * this.$blockchain.tickers.values['USD'].last ) / this.getPeriodProfitUSD()) * 100;     
            },
            changeCurrency( event ) {
                event.stopPropagation();
                this.$router.push(`${this.$route.path}?act=currency`);
            },
            getPeriodProfitUSD() {
                return (((84600 * 30 * this.contract.period * User.contracts.values.R * ( User.contracts.values.H * this.contract.ths )) / ( User.contracts.values.D * Math.pow(2, 32) )) * this.$blockchain.tickers.values["USD"].last) ;                
            },

            getPeriodProfit() {
                return (((84600 * 30 * this.contract.period * User.contracts.values.R * ( User.contracts.values.H * this.contract.ths )) / ( User.contracts.values.D * Math.pow(2, 32) )) * this.$blockchain.tickers.values[this.ticker].last) ;                
            },
            
            toTimeString( timestamp ) {
                
                let date = new Date(timestamp * 1000);
                return new Intl.DateTimeFormat('ru-RU', {
                    day: "2-digit",
                    month: '2-digit',
                    year: "numeric"
                }).format(date);
                
            }
            
        },
        computed: {
            ticker() {
                return User.currency;
            },
            
            user() {
                return User;
            }            
        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>