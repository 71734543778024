<template>
    <div>
        <div class="d-flex" style="margin-bottom: 24px;">

            <span style="font-size: 20px; font-weight: 600;">{{ miner.machine.name }}</span>

            <div style="margin: auto 5px;">
                
                <b-badge v-if="miner.status === 0" variant="danger">
                    {{$t('contracts.statuses.unpaid')}}
                </b-badge>
                <b-badge v-else-if="miner.status === 1" style="background-color: #E4EAFD; color: #426FF6; word-break: normal; word-wrap: break-word; " >
                    {{$t('contracts.statuses.start')}} {{ Utils.daysAgo( miner.start_at ) }}
                </b-badge>
                <b-badge v-else-if="miner.status === 2" >
                    {{$t('contracts.statuses.working')}}
                </b-badge>
                 <b-badge v-else-if="miner.status === 3" variant="danger">
                    {{$t('contracts.statuses.service')}}
                </b-badge>
                <b-badge v-else-if="miner.status === 4" >
                    {{$t('contracts.statuses.archive')}}
                </b-badge>
                <b-badge v-else-if="miner.status === 5" variant="primary">
                    {{$t('contracts.statuses.payment-received')}}
                </b-badge>
                <b-badge v-else-if="miner.status === 6">
                   {{$t('contracts.statuses.pause')}}
                </b-badge>

            </div>

            <b-button v-if="!miner.diagnostics && miner.is_unstable" @click="submitDiagnostics" class="my-1" size="md" variant="primary" pill block style="border-radius: 10px !important; font-size: 14px;">{{$t("miners.btns.submit-diagnostics")}}</b-button>

        </div>
        
        <card :miner="miner" />
        
        <b-tabs pills >
            <b-tab :title="$t('statistics.tabs.hashrate-detail')">
                <graphic-hashrate-view :miner="miner" />
            </b-tab>
            <b-tab :title="$t('statistics.tabs.mining-detail')">
                <detalization-view :miner="miner" />
            </b-tab>
            <b-tab :title="$t('statistics.tabs.cart-profit')">
                <b-card no-body>
                    <graphic-view :miner="miner" />
                </b-card>
            </b-tab>
        </b-tabs>
        
    </div>
</template>

<script>
    
    import Card from "./widgets/card.tablet"
    import User from "@/modules/user/"
    import detalizationView from "@/views/User/contracts/miner/tabs/detalization"
    import graphicHashrateView from "@/views/User/contracts/miner/tabs/graphicHashrate"
    import graphicView from "@/views/User/contracts/miner/tabs/graphic"
    import Utils from "@/modules/utils/"
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    
    export default {

        data() {
            return {
                Utils
            }
        },
        props: {
            miner: Object
        },
        methods: {
            submitDiagnostics() {

                this.$request.post("orders.diagnostics", {
                    "contract_miner" : this.miner.id,
                }).then( result => {
                    this.miner.diagnostics = result;
                    this.miner.status = 3;

                    if (User.self.diagnostic_orders == 0) User.self.diagnostic_orders = 1;
                    
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Майнер отправлен на диагностику',
                          text: "Подробности диагностики можно узнать в разделе Заявки",
                          icon: 'CheckCircleIcon',
                          variant: "success"
                        },
                    });

                } ).catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: this.$t('toasts.titles.error'),
                          text: err.message,
                          icon: 'AlertCircleIcon',
                          variant: "danger"
                        }
                    });
                });

            }
        },
        components: {
            Card,
            detalizationView,
            graphicView,
            graphicHashrateView
        },
        watch: {

        },
        mounted() {

        }

    }

</script>