<template>

    <b-overlay
      :show="is_loading"
      spinner-variant="primary"
      variant='transparent'
      style="min-height: 50vh;"
    >
          
        <div v-if='is_loading === false'>
            <showAt breakpoint='large'>
                <desktop :miner="miner" />
            </showAt>
            <showAt breakpoint='medium'>
                <tablet :miner="miner" />
            </showAt>
            <showAt breakpoint='small'>
                <mobile :miner="miner" />
            </showAt>
        </div>
        
    </b-overlay>
    
</template>

<script>
    
    import desktop from "./views/desktop"
    import tablet from "./views/tablet"
    import mobile from "./views/mobile"
    
    export default {

        data() {
            return {
                is_loading: true,
                miner: null
            }
        },
      
        methods: {

        },
        
        computed: {
            
        },
        
        components: {
            desktop,
            tablet,
            mobile
        },
        watch: {

        },
        mounted() {

            this.is_loading = true;
            this.$request.get("contracts.getMiner", {
                contract: this.$route.params.id,
                miner: this.$route.params.miner
            }).then( rsp => {
                this.miner = rsp;
                this.is_loading = false;
            });
        
        
        }

    }

</script>