<template>
    
    <b-overlay
        :show="is_loading"
        blur="50"
        variant='transparent'
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
    >
        <div style='min-height: 375px;'>
            <b-card-body style="padding: 24px; padding-bottom: 0;">
                <b-row class="mb-1">

                    <b-col cols="12" class="d-flex justify-content-between">
                        <div class="d-flex tab-selection">
                            <div :class=" filter.period === '1d' ? 'active' : '' " @click="setPeriod('1d')">{{$t('charts.filters.day')}}</div>
                            <div :class=" filter.period === '1w' ? 'active' : '' " @click="setPeriod('1w')">{{$t('charts.filters.week')}}</div>
                            <div :class=" filter.period === '1m' ? 'active' : '' " @click="setPeriod('1m')">{{$t('charts.filters.year')}}</div>
                        </div>
                        <div class="d-flex tab-selection">
                            <div @click="updateTicker('USD')" :class=" ticker === 'USD' ? 'active' : ''">USD</div>
                            <div @click="updateTicker('BTC')" :class=" ticker === 'BTC' ? 'active' : ''">BTC</div>
                        </div>
                    </b-col>

                </b-row>
            </b-card-body>

            <vue-apex-charts
                v-if='is_can_show_graphic'
                type="bar"
                height="355px"
                width="100%"
                :options="chartOptions"
                :series="chartData"
            />
        </div>
        
    </b-overlay>
    
</template>

<script>
    
    import VueApexCharts from 'vue-apexcharts'
    import { $themeColors } from '@themeConfig'
    import { lineChartOptions } from './chartOptions'
    
    export default {

        data() {
            return {
                is_loading: false,
                is_can_show_graphic: false,
                
                ticker: "USD",
                
                filter: {
                    period: '1d'
                },
                
                chartData: [],
                chartDataRaw: [],
                
                chartOptions: {
                    chart: {
                        toolbar: {
                          show: false,
                        },
                        sparkline: {
                          enabled: false,
                        },
                        zoom: {
                            enabled: true,
                            type: 'x',
                            autoScaleYaxis: false
                        }
                    },
                    grid: {
                        show: true,
                        padding: {
                          left: 0,
                          right: 0,
                        },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                        width: 0,
                        curve: 'smooth',
                    },
                    colors: [$themeColors.primary],
                    xaxis: {
                        categories: [],
                        type: 'category',
                        labels: {
                            show: true,
                        }
                    },
                    tooltip: {
                        x: { show: false },
                    },
                },
                
                statistic: null,
                
            }
        },
        props: {
            miner: {
                type: Object
            }
        },
        methods: {
            
            updateTicker( value ) {
                this.ticker = value;
                this.updateChart();
            },
            
            getValueByTicker( value ) {
                
                if( this.ticker === "BTC" ) {
                    return value.toFixed(3);
                }
                
                return parseInt((value * this.$blockchain.tickers.values[this.ticker].last));
                
            },
            
            setPeriod(  value ) {
                this.filter.period = value;
                this.loadGraph();
            },
            
            updateChart() {
                
                let chartData = [];
                let labels = [];
                                       
                this.chartDataRaw.forEach(data => {
                   chartData.push(this.getValueByTicker(data.y)); 
                   labels.push(data.x);
                });

                this.chartData = [
                    { name: this.ticker, data: chartData },
                ];
                
                this.chartOptions.xaxis.categories = labels;  
                
                setTimeout(() => {
                    this.is_can_show_graphic = true;
                }, 155 );

            },
            
            loadGraph() {
                
                this.is_loading = true;
                this.is_can_show_graphic = false;
                
                this.$request.get("contracts.getGraphic", {
                    for : this.miner.contract,
                    miner: this.miner.id,
                    period: this.filter.period
                }).then( result => {
                    this.chartDataRaw = result;
                    this.updateChart();
                    this.is_loading = false;
                });
                
            },
            gradientToColor(color) {
                const gradientToColors = {
                  primary: '#A9A2F6',
                  success: '#55DD92',
                  warning: '#ffc085',
                  danger: '#F97794',
                  info: '#59E0F0',
                  secondary: '#B4B9BF',
                  light: '#D0D4DB',
                  dark: '#919191',
                }

                return gradientToColors[color]
            },
        },
        
        computed: {
            chartOptionsComputed() {
                const options = JSON.parse(JSON.stringify(lineChartOptions));
                return options;
            },
        },
        
        components: {
            VueApexCharts
        },
        watch: {

        },
        mounted() {
            this.loadGraph();
        }

    }

</script>