<template>
    <div>
        <div class="d-flex" style="margin-bottom: 24px; align-items: center; justify-content: space-between;">

            <div class="d-flex">
                <span style="font-size: 20px; font-weight: 600;">{{ miner.machine.name }}</span>

            <div style="margin: auto 5px;">
                
                <b-badge v-if="miner.status === 0" variant="danger">
                    {{$t('contracts.statuses.unpaid')}}
                </b-badge>
                <b-badge v-else-if="miner.status === 1" class='badge-start-view' >
                    {{$t('contracts.statuses.start')}} {{ Utils.daysAgo( miner.start_at ) }}
                </b-badge>
                <b-badge v-else-if="miner.status === 2" >
                    {{$t('contracts.statuses.working')}}
                </b-badge>
                 <b-badge v-else-if="miner.status === 3" variant="danger">
                    {{$t('contracts.statuses.service')}}
                </b-badge>
                <b-badge v-else-if="miner.status === 4" >
                    {{$t('contracts.statuses.archive')}}
                </b-badge>
                <b-badge v-else-if="miner.status === 5" variant="primary">
                   {{$t('contracts.statuses.payment-received')}}
                </b-badge>
                <b-badge v-else-if="miner.status === 6">
                   {{$t('contracts.statuses.pause')}}
                </b-badge>

                </div>
            </div>

            <b-button v-if="!miner.diagnostics && miner.is_unstable" @click="submitDiagnostics" size="md" variant="primary" pill block style="border-radius: 10px !important; font-size: 14px; max-width: 300px">{{$t("miners.btns.submit-diagnostics")}}</b-button>

        </div>
        <div class="d-flex">
            <div style="width: 272px;">
                <card :miner="miner" />
            </div>
            <div style="padding: 0 32px; width: calc(100% - 272px);">
                <b-tabs pills >
                    <b-tab :title="$t('statistics.tabs.hashrate-detail')">
                        <graphic-hashrate-view :miner="miner" />
                    </b-tab>
                    <b-tab :title="$t('statistics.tabs.mining-detail')">
                        <detalization-view :miner="miner" />
                    </b-tab>
                    <b-tab :title="$t('statistics.tabs.cart-profit')">
                        <b-card no-body>
                            <graphic-view :miner="miner" />
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </div>
</template>

<script>
    
    import Card from "./widgets/card.desktop"
    import User from "@/modules/user/"
    import detalizationView from "@/views/User/contracts/miner/tabs/detalization"
    import graphicView from "@/views/User/contracts/miner/tabs/graphic"
    import graphicHashrateView from "@/views/User/contracts/miner/tabs/graphicHashrate"
    import Utils from "@/modules/utils/"
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    
    export default {

        data() {
            return {
                Utils,
            }
        },
        props: {
            miner: Object
        },
        methods: {
            submitDiagnostics() {

                this.$swal({
                    title: `Внимание`,
                    text: `Майнер прекращает работу, до выяснения причины нестабильной работы и устранения неисправности, в случае ее выявления`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Подтвердить',
                    cancelButtonText: 'Отмена',
                    customClass: {
                      confirmButton: 'btn btn-danger',
                      cancelButton: 'btn btn-outline-secondary ml-1',
                    },
                    buttonsStyling: false,
                }).then(result => {
                    if (result.value) {
                        this.$request.post("orders.diagnostics", {
                            "contract_miner" : this.miner.id,
                        }).then( result => {
                            this.miner.diagnostics = result;
                            this.miner.status = 3;

                            if (User.self.diagnostic_orders == 0) User.self.diagnostic_orders = 1;
                            
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                title: 'Майнер отправлен на диагностику',
                                text: "Подробности диагностики можно узнать в разделе меню «Заявки»",
                                icon: 'CheckCircleIcon',
                                variant: "success"
                                },
                            });

                        }).catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                title: this.$t('toasts.titles.error'),
                                text: err.message,
                                icon: 'AlertCircleIcon',
                                variant: "danger"
                                }
                            });
                        });
                    }
                });

            }
        },
        components: {
            Card,
            detalizationView,
            graphicView,
            graphicHashrateView
        },
        watch: {

        },
        mounted() {
            console.log(this.miner)
        }

    }

</script>