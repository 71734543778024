<template>
    <b-card v-if="statistic" no-body>
        
        <b-card-body class="data-form mb-0 pb-0" style='padding:  16px;'>
          
            <b-form-group >
                <div class="d-flex justify-content-between">
                    <div style="flex-grow: 1">
                        <!--<div class="text-muted" style="font-size: 12px;">Период</div>-->
                        <date-range-picker
                            ref="picker"
                            :locale-data="$i18n.locale == 'ru' ? calendar_locale : calendar_en"
                            :max-date="maxPickerDate"
                            :singleDatePicker="false"
                            :timePicker="false"
                            :timePicker24Hour="true"
                            :showWeekNumbers="false"
                            :showDropdowns="false"
                            :autoApply="false"
                            :linkedCalendars="false"
                            :ranges="false"
                            v-model="filters.period"
                            @update="get"
                        >
                            <template v-slot:input="picker" style="min-width: 350px;">
                                <span v-if="picker.startDate">{{ picker.startDate | date }} - {{ picker.endDate | date }}</span>
                                <span v-else class="text-muted font-weight-normal">{{$t('statistics.period')}}</span>
                            </template>

                        </date-range-picker>
                    </div>

                    <feather-icon icon="CalendarIcon" class="mt-auto mb-auto text-muted" size="18"  />

                </div>
            </b-form-group>
            
        </b-card-body>
        
        <b-card-body class=" pt-0 pb-0" style="padding: 16px;">
                
            <b-row v-if="statistic" class="mb-2">

                <b-col class="mt-auto mb-auto with-seperator" cols="6" style="border-right-width: 1px;">
                    <span class="text-muted">{{$t('statistics.total-profit')}}</span>
                    <p class="font-weight-bolder mb-0">
                        {{ getValueByTicker( statistic.summary.total_profit ) }} 
                        <span v-if="ticker !== 'BTC'">{{ User.getSymbol() }}</span> 
                        <span v-else>BTC</span>
                    </p>
                </b-col>

                <b-col class="mt-auto mb-auto with-seperator" cols="6" style="border-right-width: 1px;">
                    <span class="text-muted">{{$t('statistics.profit24')}}</span>
                    <p class="font-weight-bolder mb-0">
                        {{ getValueByTicker(  statistic.summary.daily_profit ) }} 
                        <span v-if="ticker !== 'BTC'">{{ User.getSymbol() }}</span> 
                        <span v-else>BTC</span>
                    </p>
                </b-col>

            </b-row>

        </b-card-body>

        <b-card-body class="pt-0" style="padding: 15px 16px; ">
            <div style="font-size: 12px;">
                <span class="text-muted" style='margin-right: 6px;'>{{$t('statistics.grouping')}}</span>
                <b-dropdown
                    variant="muted"
                    size="sm"
                    right
                    no-caret
                    class="group-picker"
                  >

                    <template #button-content>
                        <div class="d-flex">
                            <span class="text-primary mb-auto mb-auto font-weight-normal" style="font-size: 12px;">{{ $t(filters.group.display) }}</span>
                            <feather-icon icon="ChevronDownIcon" class="text-primary" style="margin: auto 2px;" />
                        </div>
                    </template>

                    <b-dropdown-item 
                        v-for="group in grouping" 
                        @click="filters.group = group"
                        class="text-black"
                   >
                        <div class="d-flex align-items-center">
                            <b-form-checkbox size="md" :checked="filters.group.value === group.value" /> 
                            <div class="mt-auto mb-auto">{{ group.display }}</div>
                        </div>
                    </b-dropdown-item>

                </b-dropdown>
            </div>
        </b-card-body>
        
        <template v-for="section in statistic.sections">
            
            <div class="group-header bordered d-flex justify-content-between" style="top: 0px;">
                <span class="text-black">
                    {{ section.name }}
                </span>

                <span class="text-muted" style="margin-right: 25px;">
                    +{{ getValueByTicker(section.summary) }}
                    <span v-if="ticker !== 'BTC'">{{ User.getSymbol() }}</span> 
                    <span v-else>BTC</span>
                </span>
            </div>
            
            <template v-for="(item, index) in section.items">
                <b-card v-ripple @click="toggleCollapse(item)" :bg-variant=" item.collapsed ? 'primary-light' : '' " class="mb-0" no-body style="padding: 16px;">

                    <div class="d-flex justify-content-end">
                        <span class="text-black mt-auto mb-auto mr-auto" style="font-weight: 400;">{{ item.time }}</span>
                        
                        <span class="text-black align-self-end">
                            +{{ getValueByTicker(item.profit) }} 
                            <span v-if="ticker !== 'BTC'" class='text-muted'>{{ User.getSymbol() }}</span> 
                            <span v-else class='text-muted'>₿</span>
                        </span>
                        <div class=" mt-auto mb-auto d-flex align-self-end" style=" border-radius: 18px; margin-left: 12px; margin-right: -8px;">
                            <feather-icon :icon=" item.collapsed ? 'ChevronsUpIcon' : 'ChevronsDownIcon' " :class=" item.collapsed ? 'text-primary m-auto'  : 'text-muted m-auto' " size="20" />
                        </div>
                        
                    </div>
                    
                    <b-collapse v-model="item.collapsed" >
                        <div style="padding: 13px 0; padding-right: 25px;">
                            <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
                                <span class="text-muted">{{$t('tables.total-profit')}}</span>
                                <span>
                                    {{ getValueByTicker(item.profit + item.energy) }} 
                                    <span v-if="ticker !== 'BTC'">{{ User.getSymbol() }}</span> 
                                    <span v-else>BTC</span>
                                </span>
                            </div>
                            <div class="d-flex justify-content-between"  style="margin-bottom: 4px;">
                                <i18n path="tables.net-profit" class="text-muted" tag="span">
                                </i18n>
                                <span>
                                    {{ getValueByTicker(item.profit) }} 
                                    <span v-if="ticker !== 'BTC'">{{ User.getSymbol() }}</span> 
                                    <span v-else>BTC</span>
                                </span>
                            </div>
                            <div class="d-flex justify-content-between"  style="margin-bottom: 4px;">
                                <span class="text-muted">{{$t('tables.service-fee')}}</span>
                                <span>
                                    {{ getValueByTicker((item.profit + item.energy) * 0.05) }} 
                                    <span v-if="ticker !== 'BTC'">{{ User.getSymbol() }}</span> 
                                    <span v-else>BTC</span>
                                </span>
                            </div>
                            <div class="d-flex justify-content-between"  style="margin-bottom: 4px;">
                                <span class="text-muted">{{$t('tables.energy')}}</span>
                                <span>
                                    {{ getValueByTicker(item.energy) }} 
                                    <span v-if="ticker !== 'BTC' ">{{ User.getSymbol() }}</span> 
                                    <span v-else>BTC</span>
                                </span>
                            </div>
                            <div class="d-flex justify-content-between">
                                <span class="text-muted">{{$t('tables.complexity-network')}}</span>
                                <span>{{ item.difficult.formatMoney(0, "", " ") }}</span>
                            </div>
                        </div>
                    </b-collapse>
                  

                </b-card>

                <div :class="{
                    'separator' : true,
                    'ml-0' : section.items.length - 1 === index
                }"></div>

            </template>
            
        </template>
        
        
        <template v-if="statistic.sections.length === 0">
            <div class="d-flex flex-column justify-content-center" style="padding: 16px;">
                <div class="nodata-icon ml-auto mr-auto"></div>
                <p class="text-muted mt-1 ml-auto mr-auto text-center">
                    {{$t('tables.no-data')}}
                </p>
            </div>
        </template>
        
    </b-card>
</template>

<script>

    import User from "@/modules/user/"
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    
    export default {

        data() {
            return {
                User,
                
                is_loading: false,
                
                ticker: User.currency,
                
                maxPickerDate: new Date(),
                calendar_locale: {
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                    applyLabel: 'Применить',
                    cancelLabel: 'Отмена',
                    weekLabel: 'Н',
                    customRangeLabel: 'Период',
                    daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                    monthNames: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авн', 'Сен', 'Окт', 'Нов', 'Дек'],
                    firstDay: 0
                },

                calendar_en: {
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                    applyLabel: 'Apply',
                    cancelLabel: 'Cancel',
                    weekLabel: 'Н',
                    customRangeLabel: 'Period',
                    daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                    monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    firstDay: 0
                },
                
                filters: {
                    period:{
                        startDate: null,
                        endDate: null
                    },
                    group: {
                        display: "statistics.grouping-type.day",
                        value: "day"
                    }
                },
                
                statistic: null,
                
                grouping: [
                    { value: "day", display: "statistics.grouping-type.day" },
                    { value: "week", display: "statistics.grouping-type.week" },
                    { value: "month", display: "statistics.grouping-type.month" },
                    { value: "", display: "statistics.grouping-type.without" },
                ],
            }
        },
        
        props: {
            miner: {
                type: Object
            }
        },
        
        filters: {
            date(val) {
              return val ? new Intl.DateTimeFormat('ru', { day: 'numeric',  year: '2-digit', month: 'numeric' }).format(val) : ''
            }
        },
        
        methods: {
            
            toggleCollapse(item) {
                
                if( item.collapsed === null || typeof(item.collapsed) === "undefined" ) {
                    item.collapsed = true;
                } else {
                    item.collapsed = !item.collapsed; 
                }
                
            },
            
            getEnergyByTicker( value ) {
                
                if( this.ticker === "BTC" ) {
                    return value.toFixed(6);
                }
                
                return (value * this.$blockchain.tickers.values[User.currency].last).formatMoney(6, "", " ");
                
            },
            
            getValueByTicker( value ) {
                
                if( this.ticker === "BTC" ) {
                    return value.toFixed(12);
                }
                
                return (value * this.$blockchain.tickers.values[User.currency].last).formatMoney(2, "", " ");
                
            },
            
            setPeriod(  value ) {
                this.filter.period = value;
//                this.updateStatistic();
            },
            
            updateStatistic() {
                
                this.is_loading = true;
                
                this.$request.get('contracts.getStatistic', {
                    "for": this.contract.id,
                    period: this.filter.period
                }).then( statistic => {
                    this.statistic = statistic;
                    this.is_loading = false;
                });
                
            },
            
            linkGen( pageNum ) {
              return `${this.$route.path}?page=${pageNum}`;                  
            },
            
            handleScroll( e ) {
                
                if(!this.statistic) return;
                if(!this.statistic.has_more_pages) return;
                if( this.is_loading ) return;
                
                let el = e.target;
               
                if( el.scrollTop + el.offsetHeight > ( el.scrollHeight - 255 ) ) {
                    
                  let params = this.params;
                  
                  params['page'] = this.statistic.current_page + 1;
                  
                  this.is_loading = true;
                  
                  this.$request.get("contracts.getStatistic", params ).then( result => {
                       this.statistic.has_more_pages = result.has_more_pages;
                       this.statistic.current_page = result.current_page;
                       this.statistic.sections.push( ...result.sections );
                       this.is_loading = false;                       
                  });
                  
                }
                
            },
            
            get() {
                this.$request.get('contracts.getStatistic', this.params ).then( statistic => {
                     this.statistic = statistic;
                });
            },
            
        },
        components: {
            DateRangePicker
        },
        watch: {
            "filters.group" : function() {
                this.get();
            },
        },
        computed: {
            params() {
                
                let period = null;                
                
                if( this.filters.period.startDate && this.filters.period.endDate ) {
                    period = {};
                    period['start'] = this.filters.period.startDate  ;
                    period['end'] = this.filters.period.endDate  ;
                }
                
                let params = {
                    for: this.miner.contract,
                    miner: this.miner.id,
                    period: period,
                    group: this.filters.group.value,
                }
                
                
                if( this.$route.query.page ) {
                    params['page'] = this.$route.query.page;
                }
                
                return params;
            }
        },
        created () {
            document.getElementsByClassName('content-wrapper')[0].addEventListener('scroll', this.handleScroll);
        },
        destroyed () {
            document.getElementsByClassName('content-wrapper')[0].removeEventListener('scroll', this.handleScroll);
        },
        mounted() {
            this.get();            
        }

    }

</script>